exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PhotoPage_container_ihW94qjtCc2YALqhpxuo9 {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center; }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/pages/photo/containers/PhotoPage.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,oBAAoB;EACpB,gBAAgB;EAChB,wBAAwB,EAAE","file":"PhotoPage.scss","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  justify-content: center; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "PhotoPage_container_ihW94qjtCc2YALqhpxuo9"
};