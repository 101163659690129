exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".YoutubePlayer_container_2vUZT6UNd3o6iGPgRcvSP {\n  width: 100%;\n  text-align: center;\n  padding-top: 40px;\n  padding-bottom: 40px; }\n\n.YoutubePlayer_video_1wETEuo6tV630QR6YACxyi {\n  width: 560px;\n  height: 350px; }\n\n@media (max-width: 800px) {\n  .YoutubePlayer_video_1wETEuo6tV630QR6YACxyi {\n    width: 100%;\n    height: calc(10 / 16 * 100vw); } }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/pages/video/components/YoutubePlayer.scss"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,qBAAqB,EAAE;;AAEzB;EACE,aAAa;EACb,cAAc,EAAE;;AAElB;EACE;IACE,YAAY;IACZ,8BAA8B,EAAE,EAAE","file":"YoutubePlayer.scss","sourcesContent":[".container {\n  width: 100%;\n  text-align: center;\n  padding-top: 40px;\n  padding-bottom: 40px; }\n\n.video {\n  width: 560px;\n  height: 350px; }\n\n@media (max-width: 800px) {\n  .video {\n    width: 100%;\n    height: calc(10 / 16 * 100vw); } }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "YoutubePlayer_container_2vUZT6UNd3o6iGPgRcvSP",
	"video": "YoutubePlayer_video_1wETEuo6tV630QR6YACxyi"
};