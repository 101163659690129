exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AboutMe_container_2Jpcr3ic7lwYOd7dI5KPh- {\n  margin: 10px; }\n\n.markdown-container pre {\n  font-size: 1.3em;\n  font-style: italic;\n  padding-left: 20px;\n  color: #666; }\n\n.markdown-flex-container {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap; }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/pages/aboutme/components/AboutMe.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE;;AAEjB;EACE,iBAAiB;EACjB,mBAAmB;EACnB,mBAAmB;EACnB,YAAY,EAAE;;AAEhB;EACE,cAAc;EACd,wBAAwB;EACxB,gBAAgB,EAAE","file":"AboutMe.scss","sourcesContent":[".container {\n  margin: 10px; }\n\n:global .markdown-container pre {\n  font-size: 1.3em;\n  font-style: italic;\n  padding-left: 20px;\n  color: #666; }\n\n:global .markdown-flex-container {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "AboutMe_container_2Jpcr3ic7lwYOd7dI5KPh-"
};