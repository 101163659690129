exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProjectHeader_avatar_3P99gEiL67RNlOkt2vV568 {\n  flex: 0 0 auto;\n  margin-right: 16px; }\n\n.ProjectHeader_dates_2qopPetgLSlB2ngoL25uVj {\n  flex: 0 0 auto;\n  align-self: flex-start;\n  margin-top: -8px;\n  margin-right: -16px; }\n\n.ProjectHeader_content_197zbvuIIYytvKECJapEP_ {\n  flex: 1 1 auto; }\n\n.ProjectHeader_title_2Kgu-i3gtddFBQk2E7qX1r {\n  padding-right: 10px; }\n\n.ProjectHeader_subheader_1qTrZsW_y-G9WJl5Yht7qg {\n  padding-top: 2px;\n  font-style: italic;\n  color: #666 !important; }\n\n@media (max-width: 800px) {\n  .ProjectHeader_title_2Kgu-i3gtddFBQk2E7qX1r {\n    font-size: 1.1em !important; }\n  .ProjectHeader_dates_2qopPetgLSlB2ngoL25uVj {\n    margin-right: -8px;\n    font-size: 0.6em !important; }\n  .ProjectHeader_subheader_1qTrZsW_y-G9WJl5Yht7qg {\n    font-size: 0.6em !important; } }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/pages/projects/components/ProjectHeader.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB,EAAE;;AAEvB;EACE,eAAe;EACf,uBAAuB;EACvB,iBAAiB;EACjB,oBAAoB,EAAE;;AAExB;EACE,eAAe,EAAE;;AAEnB;EACE,oBAAoB,EAAE;;AAExB;EACE,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB,EAAE;;AAE3B;EACE;IACE,4BAA4B,EAAE;EAChC;IACE,mBAAmB;IACnB,4BAA4B,EAAE;EAChC;IACE,4BAA4B,EAAE,EAAE","file":"ProjectHeader.scss","sourcesContent":[".avatar {\n  flex: 0 0 auto;\n  margin-right: 16px; }\n\n.dates {\n  flex: 0 0 auto;\n  align-self: flex-start;\n  margin-top: -8px;\n  margin-right: -16px; }\n\n.content {\n  flex: 1 1 auto; }\n\n.title {\n  padding-right: 10px; }\n\n.subheader {\n  padding-top: 2px;\n  font-style: italic;\n  color: #666 !important; }\n\n@media (max-width: 800px) {\n  .title {\n    font-size: 1.1em !important; }\n  .dates {\n    margin-right: -8px;\n    font-size: 0.6em !important; }\n  .subheader {\n    font-size: 0.6em !important; } }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"avatar": "ProjectHeader_avatar_3P99gEiL67RNlOkt2vV568",
	"dates": "ProjectHeader_dates_2qopPetgLSlB2ngoL25uVj",
	"content": "ProjectHeader_content_197zbvuIIYytvKECJapEP_",
	"title": "ProjectHeader_title_2Kgu-i3gtddFBQk2E7qX1r",
	"subheader": "ProjectHeader_subheader_1qTrZsW_y-G9WJl5Yht7qg"
};