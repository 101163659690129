exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Work_card_zqAa0eaDFz6av__KMhD0E {\n  margin: 10px; }\n\n.Work_media_2_0a_F4Ek_WkjfHnzJRBeN {\n  height: 194px; }\n\n.Work_techenv_1ApufmaIFiu5mMSo-C16pP {\n  padding: 10px 40px 15px; }\n\n@media (max-width: 800px) {\n  .Work_card_zqAa0eaDFz6av__KMhD0E {\n    margin: 10px 0; }\n  .Work_techenv_1ApufmaIFiu5mMSo-C16pP {\n    padding: 10px 20px 15px; }\n  .Work_cardContent_YSaxujwkLetg3VFQtymRa {\n    padding: 4px !important; } }\n\n.Work_skills_2N9qBByGCOPXti14rhOGlC {\n  display: flex;\n  flex-wrap: wrap; }\n  .Work_skills_2N9qBByGCOPXti14rhOGlC > * {\n    margin: 2px; }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/pages/work/components/Work.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE;;AAEjB;EACE,cAAc,EAAE;;AAElB;EACE,wBAAwB,EAAE;;AAE5B;EACE;IACE,eAAe,EAAE;EACnB;IACE,wBAAwB,EAAE;EAC5B;IACE,wBAAwB,EAAE,EAAE;;AAEhC;EACE,cAAc;EACd,gBAAgB,EAAE;EAClB;IACE,YAAY,EAAE","file":"Work.scss","sourcesContent":[".card {\n  margin: 10px; }\n\n.media {\n  height: 194px; }\n\n.techenv {\n  padding: 10px 40px 15px; }\n\n@media (max-width: 800px) {\n  .card {\n    margin: 10px 0; }\n  .techenv {\n    padding: 10px 20px 15px; }\n  .cardContent {\n    padding: 4px !important; } }\n\n.skills {\n  display: flex;\n  flex-wrap: wrap; }\n  .skills > * {\n    margin: 2px; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"card": "Work_card_zqAa0eaDFz6av__KMhD0E",
	"media": "Work_media_2_0a_F4Ek_WkjfHnzJRBeN",
	"techenv": "Work_techenv_1ApufmaIFiu5mMSo-C16pP",
	"cardContent": "Work_cardContent_YSaxujwkLetg3VFQtymRa",
	"skills": "Work_skills_2N9qBByGCOPXti14rhOGlC"
};