exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".WorkXPHeader_avatar_28YOgY-UO1qXm5ZtbX_Dy3 {\n  flex: 0 0 auto;\n  margin-right: 16px; }\n\n.WorkXPHeader_dates_ciUX83yC9T6EvpkbPVC3P {\n  flex: 0 0 auto;\n  align-self: flex-start;\n  margin-top: -8px;\n  margin-right: -16px; }\n\n.WorkXPHeader_content_kUT8JYsDn6Dx-eBXKGEoU {\n  flex: 1 1 auto; }\n\n.WorkXPHeader_title_105mvQqJ88OQM9LCQ16sWI {\n  padding-right: 10px; }\n\n.WorkXPHeader_subheader_2OTI8A6bXqMjLX3RuNkCuS {\n  padding-top: 2px;\n  font-style: italic;\n  color: #666 !important; }\n\n@media (max-width: 800px) {\n  .WorkXPHeader_title_105mvQqJ88OQM9LCQ16sWI {\n    font-size: 1.1em !important; }\n  .WorkXPHeader_dates_ciUX83yC9T6EvpkbPVC3P {\n    margin-right: -8px;\n    font-size: 0.6em !important; }\n  .WorkXPHeader_subheader_2OTI8A6bXqMjLX3RuNkCuS {\n    font-size: 0.6em !important; } }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/pages/work/components/WorkXPHeader.scss"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,mBAAmB,EAAE;;AAEvB;EACE,eAAe;EACf,uBAAuB;EACvB,iBAAiB;EACjB,oBAAoB,EAAE;;AAExB;EACE,eAAe,EAAE;;AAEnB;EACE,oBAAoB,EAAE;;AAExB;EACE,iBAAiB;EACjB,mBAAmB;EACnB,uBAAuB,EAAE;;AAE3B;EACE;IACE,4BAA4B,EAAE;EAChC;IACE,mBAAmB;IACnB,4BAA4B,EAAE;EAChC;IACE,4BAA4B,EAAE,EAAE","file":"WorkXPHeader.scss","sourcesContent":[".avatar {\n  flex: 0 0 auto;\n  margin-right: 16px; }\n\n.dates {\n  flex: 0 0 auto;\n  align-self: flex-start;\n  margin-top: -8px;\n  margin-right: -16px; }\n\n.content {\n  flex: 1 1 auto; }\n\n.title {\n  padding-right: 10px; }\n\n.subheader {\n  padding-top: 2px;\n  font-style: italic;\n  color: #666 !important; }\n\n@media (max-width: 800px) {\n  .title {\n    font-size: 1.1em !important; }\n  .dates {\n    margin-right: -8px;\n    font-size: 0.6em !important; }\n  .subheader {\n    font-size: 0.6em !important; } }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"avatar": "WorkXPHeader_avatar_28YOgY-UO1qXm5ZtbX_Dy3",
	"dates": "WorkXPHeader_dates_ciUX83yC9T6EvpkbPVC3P",
	"content": "WorkXPHeader_content_kUT8JYsDn6Dx-eBXKGEoU",
	"title": "WorkXPHeader_title_105mvQqJ88OQM9LCQ16sWI",
	"subheader": "WorkXPHeader_subheader_2OTI8A6bXqMjLX3RuNkCuS"
};