exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "#Menu_menu_2Frw9ZN6c1yqqQN7zZVAOo {\n  position: absolute;\n  top: 0; }\n  #Menu_menu_2Frw9ZN6c1yqqQN7zZVAOo button {\n    padding-top: 15px;\n    color: #ffffff; }\n  #Menu_menu_2Frw9ZN6c1yqqQN7zZVAOo a {\n    color: black; }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/partials/header/menu/Menu.scss"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,OAAO,EAAE;EACT;IACE,kBAAkB;IAClB,eAAe,EAAE;EACnB;IACE,aAAa,EAAE","file":"Menu.scss","sourcesContent":["#menu {\n  position: absolute;\n  top: 0; }\n  #menu button {\n    padding-top: 15px;\n    color: #ffffff; }\n  #menu a {\n    color: black; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"menu": "Menu_menu_2Frw9ZN6c1yqqQN7zZVAOo"
};