exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"dimensExport.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"textSizeXS": "0.6em",
	"textSizeSM": "0.95em",
	"textSizeMD": "1.1em",
	"textSizeL": "1.2em",
	"textSizeXL": "1.3em",
	"textSizeXXL": "1.8em",
	"lineHeightXS": "1.17em",
	"lineHeightSM": "1.35em",
	"lineHeightMD": "1.4em",
	"lineHeightL": "1.5em",
	"lineHeightXL": "1.7em",
	"spacingUnit": "8px"
};