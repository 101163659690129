exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "#Footer_footerContainer_2KIPLv-jVoHPgZJXbaq0Z7 {\n  display: flex;\n  height: 48px;\n  background-color: #004c8c; }\n\n#Footer_footer_1lph4Cx3PhvhiYiZOIpema {\n  margin: auto;\n  vertical-align: middle; }\n\n@media (max-width: 800px) {\n  #Footer_footerContainer_2KIPLv-jVoHPgZJXbaq0Z7 {\n    height: 80px;\n    vertical-align: bottom; }\n  #Footer_footer_1lph4Cx3PhvhiYiZOIpema {\n    text-align: center;\n    padding-top: 20px;\n    padding-left: 10px;\n    padding-right: 10px; } }\n\n.Footer_text_U0V72tfj3HOpAKnAZhPAR {\n  color: #dddddd !important;\n  font-size: 0.6em; }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/partials/footer/Footer.scss"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,0BAA0B,EAAE;;AAE9B;EACE,aAAa;EACb,uBAAuB,EAAE;;AAE3B;EACE;IACE,aAAa;IACb,uBAAuB,EAAE;EAC3B;IACE,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,oBAAoB,EAAE,EAAE;;AAE5B;EACE,0BAA0B;EAC1B,iBAAiB,EAAE","file":"Footer.scss","sourcesContent":["#footerContainer {\n  display: flex;\n  height: 48px;\n  background-color: #004c8c; }\n\n#footer {\n  margin: auto;\n  vertical-align: middle; }\n\n@media (max-width: 800px) {\n  #footerContainer {\n    height: 80px;\n    vertical-align: bottom; }\n  #footer {\n    text-align: center;\n    padding-top: 20px;\n    padding-left: 10px;\n    padding-right: 10px; } }\n\n.text {\n  color: #dddddd !important;\n  font-size: 0.6em; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"footerContainer": "Footer_footerContainer_2KIPLv-jVoHPgZJXbaq0Z7",
	"footer": "Footer_footer_1lph4Cx3PhvhiYiZOIpema",
	"text": "Footer_text_U0V72tfj3HOpAKnAZhPAR"
};