exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "\n", "", {"version":3,"sources":[],"names":[],"mappings":"","file":"paletteExport.scss","sourceRoot":""}]);

// exports
exports.locals = {
	"colorPrimary": "#0277bd",
	"colorPrimaryDark": "#004c8c",
	"colorPrimaryLight": "#58a5f0",
	"colorSecondary": "#ffd180",
	"colorSecondaryDark": "#caa052",
	"colorSecondaryLight": "#ffffb1",
	"textColorPrimary": "rgba(0, 0, 0, 0.87)",
	"textColorPrimaryLight": "rgba(255, 255, 255, 0.87)",
	"textColorSecondary": "#333",
	"textColorSecondaryLight": "rgba(255, 255, 255, 0.54)",
	"textColorDisabled": "rgba(200, 200, 200, 0.87)",
	"textColorHint": "rgba(100, 200, 100, 0.87)",
	"textColorError": "rgba(200, 100, 100, 0.87)"
};