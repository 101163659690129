exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "@media (max-width: 800px) {\n  .Skill_skill_1_t4xeTmpfDKddmcyOrsn2 {\n    height: 24px !important; }\n    .Skill_skill_1_t4xeTmpfDKddmcyOrsn2 > div {\n      max-width: 24px !important;\n      height: 24px !important; } }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/partials/skill/Skill.scss"],"names":[],"mappings":"AAAA;EACE;IACE,wBAAwB,EAAE;IAC1B;MACE,2BAA2B;MAC3B,wBAAwB,EAAE,EAAE","file":"Skill.scss","sourcesContent":["@media (max-width: 800px) {\n  .skill {\n    height: 24px !important; }\n    .skill > div {\n      max-width: 24px !important;\n      height: 24px !important; } }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"skill": "Skill_skill_1_t4xeTmpfDKddmcyOrsn2"
};