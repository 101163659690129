exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Project_card_3pzfAQYIKK-2MtGfCoeuIU {\n  margin: 10px; }\n\n.Project_techenv_1mJiwhDVMDelJmfeCCIZqv {\n  padding: 10px 40px 15px; }\n\n@media (max-width: 800px) {\n  .Project_card_3pzfAQYIKK-2MtGfCoeuIU {\n    margin: 10px 0; }\n  .Project_techenv_1mJiwhDVMDelJmfeCCIZqv {\n    padding: 10px 20px 15px; } }\n\n.Project_media_1ptRMsZR-YEDi83j8Tsd49 {\n  height: 194px; }\n\n.Project_skills_3DJFrxBij-rFmtT4sk7VNk {\n  display: flex;\n  flex-wrap: wrap; }\n  .Project_skills_3DJFrxBij-rFmtT4sk7VNk > * {\n    margin: 2px; }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/pages/projects/components/Project.scss"],"names":[],"mappings":"AAAA;EACE,aAAa,EAAE;;AAEjB;EACE,wBAAwB,EAAE;;AAE5B;EACE;IACE,eAAe,EAAE;EACnB;IACE,wBAAwB,EAAE,EAAE;;AAEhC;EACE,cAAc,EAAE;;AAElB;EACE,cAAc;EACd,gBAAgB,EAAE;EAClB;IACE,YAAY,EAAE","file":"Project.scss","sourcesContent":[".card {\n  margin: 10px; }\n\n.techenv {\n  padding: 10px 40px 15px; }\n\n@media (max-width: 800px) {\n  .card {\n    margin: 10px 0; }\n  .techenv {\n    padding: 10px 20px 15px; } }\n\n.media {\n  height: 194px; }\n\n.skills {\n  display: flex;\n  flex-wrap: wrap; }\n  .skills > * {\n    margin: 2px; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"card": "Project_card_3pzfAQYIKK-2MtGfCoeuIU",
	"techenv": "Project_techenv_1mJiwhDVMDelJmfeCCIZqv",
	"media": "Project_media_1ptRMsZR-YEDi83j8Tsd49",
	"skills": "Project_skills_3DJFrxBij-rFmtT4sk7VNk"
};