exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".VideoPage_container_1xGrhqAwClSS_omQgI_fXF {\n  width: 100%; }\n", "", {"version":3,"sources":["/home/travis/build/loicortola/website/client/src/pages/video/containers/VideoPage.scss"],"names":[],"mappings":"AAAA;EACE,YAAY,EAAE","file":"VideoPage.scss","sourcesContent":[".container {\n  width: 100%; }\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "VideoPage_container_1xGrhqAwClSS_omQgI_fXF"
};